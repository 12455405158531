import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useKeycloak } from '@react-keycloak/web';
import LocaleSwitcher from '@super-template/core/src/LocaleSwitcher';
import SettingsMenu from '@super-template/lab/src/SettingsMenu';
import config from '@visma/public.config';
import useLocale from '@visma/react-app-locale-utils/lib/useLocale.js';
import { HasRole, IsAuthenticated } from '@visma/react-keycloak';
import { path as home } from 'components/Routes/home';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import UserMenu from './UserMenu';

const useStyles = makeStyles((theme) => ({
  logo: {
    [theme.breakpoints.up('sm')]: {
      height: theme.mixins.toolbar.minHeight - 10,
    },
    [theme.breakpoints.down('xs')]: {
      width: 48,
    },
  },
  title: {
    marginLeft: theme.spacing(1),
    flexGrow: 1,
  },
}));

export default function AppBar({ title }) {
  const classes = useStyles();
  const intl = useIntl();
  const [locale] = useLocale();
  const lang = locale.split('-')[0];
  const logo = config.appbar?.logo[lang];
  const { keycloak } = useKeycloak();
  const reloadOnLocaleSwitch = config?.reloadOnLocaleSwitch
    ? document.location.pathname.startsWith('/forms/')
    : false;

  return (
    <>
      <Link to={home}>
        <img
          src={logo}
          className={classes.logo}
          alt={intl.formatMessage({ defaultMessage: 'Logo' })}
        />
      </Link>
      <Typography component="h1" variant="h6" className={classes.title}>
        {title}
      </Typography>
      <HasRole resource={{ [config.keycloak.clientId]: 'configurer' }}>
        <Button color="inherit" component={Link} to="/admin">
          <FormattedMessage defaultMessage="Hallinta" />
        </Button>
      </HasRole>
      <LocaleSwitcher reloadOnLocaleSwitch={reloadOnLocaleSwitch} />
      <IsAuthenticated
        fallback={
          <Button
            color="inherit"
            onClick={() => keycloak.login(config.keycloakLogin)}
          >
            <FormattedMessage defaultMessage="Kirjaudu sisään" />
          </Button>
        }
      >
        <UserMenu />
      </IsAuthenticated>
      <SettingsMenu />
    </>
  );
}

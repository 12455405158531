import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ExpandMore, Language } from '@material-ui/icons';
import displayNames from '@visma/react-app-locale-utils/lib/displayNames';
import useDisplayName from '@visma/react-app-locale-utils/lib/useDisplayName';
import useLocale from '@visma/react-app-locale-utils/lib/useLocale';
import { useIntl, FormattedMessage } from 'react-intl';
import useMenu from './hooks/useMenu';

const useStyles = makeStyles((theme) => ({
  text: {
    margin: theme.spacing(0, 0.5, 0, 1),
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
}));

export default function LocaleSwitcher({ reloadOnLocaleSwitch }) {
  const classes = useStyles();
  const intl = useIntl();
  const { anchorEl, isOpen, menuId, handleOpen, handleClose } =
    useMenu('locale-switcher');
  const [locale, setLocale] = useLocale();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [localeDialog, setLocaleDialog] = useState(null);

  const onClose = (confirm) => {
    setDialogOpen(false);
    if (confirm) {
      setLocale(localeDialog);
      window.location.reload();
    }
  };

  const handleLocaleChange = (newLocale) => {
    if (reloadOnLocaleSwitch) {
      setLocaleDialog(newLocale);
      setDialogOpen(true);
    } else {
      setLocale(newLocale);
    }
  };

  useEffect(() => {
    document.documentElement.lang = locale.split('-')[0];
  }, [locale]);

  return (
    <>
      <Button
        aria-label={intl.formatMessage({
          defaultMessage: 'kielivalikko',
        })}
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleOpen}
        color="inherit"
      >
        <Language />
        <span className={classes.text}>{useDisplayName()}</span>
        <ExpandMore fontSize="small" />
      </Button>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen}
        onClose={handleClose}
      >
        {displayNames.map((displayName) => (
          <MenuItem
            key={displayName.locale}
            selected={locale === displayName.locale}
            lang={displayName.locale.split('-')[0]}
            onClick={() => {
              handleLocaleChange(displayName.locale);
              handleClose();
            }}
          >
            {displayName.value}
          </MenuItem>
        ))}
      </Menu>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          <FormattedMessage defaultMessage={'Vaihda sivuston kieli'} />
        </DialogTitle>
        <DialogContent>
          <FormattedMessage
            defaultMessage={
              'Sivuston kielen vaihtaminen poistaa kaikki syötetyt tiedot. Oletko varma, että haluat vaihtaa kielen?'
            }
          />
        </DialogContent>
        <DialogActions>
          <Button variant={'outlined'} onClick={() => onClose(false)}>
            <FormattedMessage defaultMessage={'Peruuta'} />
          </Button>
          <Button variant={'contained'} onClick={() => onClose(true)}>
            <FormattedMessage defaultMessage={'Kyllä'} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
